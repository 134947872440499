<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
                <b-col md="12" class="table-responsive">
                  <table class="table">
                    <tr>
                      <th>{{ $t('org_pro.organization') }}</th>
                      <td>{{ orgList.find(e=>e.value===item.org_id).text }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.project_title') }}</th>
                      <td v-if="localeLang === 'bn'">{{ item.project_title_bn }}</td>
                      <td v-else>{{ item.project_title }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.budget') }}</th>
                      <td >{{ item.budget }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.research_type') }}</th>
                      <td >{{ options.find((e) => e.value === item.type_of_research).text }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('globalTrans.description') }}</th>
                      <td v-if="localeLang === 'bn'">{{ item.project_description_bn }}</td>
                      <td v-else>{{ item.project_description }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.start_year') }}</th>
                      <td>{{monthList.find(e => e.value == item.project_start_month).text}}, {{ item.project_start_year }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.end_year') }}</th>
                      <td> {{monthList.find(e => e.value == item.project_end_month).text}}, {{ item.project_end_year }}</td>
                    </tr>
                  </table>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  props: ['item'],
  components: {
  },
  created () {
      const tmp = this.item
      this.project = tmp
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        project: {},
        options: [
            { value: 1, text: 'Coordinate Research' },
            { value: 2, text: 'Plain Research' }
        ]
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    orgList: function () {
        const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
        return objectData.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
    },
    yearList: function () {
        return this.$store.state.AgriResearch.commonObj.yearList
    },
    monthList: function () {
        return this.$store.state.commonObj.monthList
    }
  },
  methods: {
  }
}
</script>
