<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay :show="loadingState">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Organization" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="project_info.org_id"
                                            :options="orgList"
                                            id="org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Project Title(En)" vid="project_title" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="project_title"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.project_title_en')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="project_title"
                                        v-model="project_info.project_title"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Project Title(Bn)" vid="project_title_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="project_title_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.project_title_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="project_title_bn"
                                        v-model="project_info.project_title_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Budget" vid="budget" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="budget"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.budget')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="number"
                                        id="budget"
                                        v-model="project_info.budget"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Project Description(En)" vid="project_description" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="project_description"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.project_description')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea
                                        id="project_description"
                                        v-model="project_info.project_description"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Project Description(Bn)" vid="project_description_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="project_description_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.project_description_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea
                                        id="project_description_bn"
                                        v-model="project_info.project_description_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="project_start_year" vid="project_start_year" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="project_start_year "
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('research_manage.project_start_year')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="project_info.project_start_year"
                                            id="project_start_year"
                                            :options="yearList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="project_start_month" vid="project_start_month" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="project_start_month "
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('research_manage.project_start_month')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="project_info.project_start_month"
                                            id="project_start_month"
                                            :options="monthList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Project End Year" vid="project_end_year" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="project_end_year "
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('research_manage.project_end_year')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="project_info.project_end_year"
                                            id="project_end_year"
                                            :options="yearList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Project End Month" vid="project_end_month" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="project_end_month"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('research_manage.project_end_month')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="project_info.project_end_month"
                                            id="project_end_month"
                                            :options="monthList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="8" sm="12">
                                    <ValidationProvider name="Research Type" vid="type_of_research" rules="required">
                                        <b-form-group
                                            class="row justify-content-between"
                                            label-cols-sm="12"
                                            label-for="type_of_research"
                                            slot-scope="{ valid,errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('research_manage.research_type')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-radio-group
                                                v-model="project_info.type_of_research"
                                                :options="options"
                                                class="mb-3"
                                                name="checkbox-validation"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                   <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">Please select one</b-form-invalid-feedback>
                                                </b-form-radio-group>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { projectInfoStore, projectInfoUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        options: [
          { value: 1, text: 'Coordinate Research' },
          { value: 2, text: 'Plain Research' }
        ],
        project_info: {
            org_id: '',
            project_title: '',
            project_title_bn: '',
            project_description: '',
            project_description_bn: '',
            budget: '',
            project_start_year: new Date().getFullYear(),
            project_start_month: 0,
            project_end_year: new Date().getFullYear(),
            project_end_month: 0,
            type_of_research: ''
        }
    }
  },
  computed: {
    orgList: function () {
      const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      return objectData.map(project => {
            return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
          })
    },
    yearList: function () {
      return this.$store.state.commonObj.yearList
    },
    monthList: function () {
      return this.$store.state.commonObj.monthList
    }
  },
  created () {
    if (this.id) {
        const tmp = this.getProjectData()
        // Object.freeze(tmp)
        this.project_info = tmp
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getProjectData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${projectInfoUpdate}/${this.id}`, this.project_info)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, projectInfoStore, this.project_info)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
